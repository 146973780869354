import { useRef, useCallback } from 'react';
import PhoneInput from 'react-phone-number-input/input';
import { Input } from 'antd';

//* Style
import FormInputStyle from './style';

const FormInput = ({ isTextAreaInput = false, isPhoneInput = false, uppercase = false, ...props }) => {
	//! Ref
	const ref = useRef();

	//! On Wheel
	const onWheel = useCallback((e) => e.preventDefault(), []);

	//! On Focus
	const onFocus = useCallback(() => {
		if (ref.current?.props?.type === 'number') {
			ref.current.input.addEventListener('wheel', onWheel);
		}
	}, [ref, onWheel]);

	//! On Blur
	const onBlur = useCallback(() => {
		if (ref.current?.props?.type === 'number') {
			ref.current.input.removeEventListener('wheel', onWheel);
		}
	}, [ref, onWheel]);

	//! On Uppercase
	const inputFirstLatterUppercase = uppercase
		? (e) => {
				let inputValue = e.target.value.trimStart();
				if (e.target.value.length > 0) {
					e.target.value = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
				}
		  }
		: null;

	return (
		<FormInputStyle>
			{isTextAreaInput ? (
				<Input.TextArea
					ref={ref}
					onBlur={onBlur}
					onFocus={onFocus}
					prefix={props.prefix}
					suffix={props.suffix}
					step={props.step && '0.1'}
					onInput={inputFirstLatterUppercase}
					{...props}
				/>
			) : isPhoneInput ? (
				<PhoneInput
					ref={ref}
					onBlur={onBlur}
					onFocus={onFocus}
					{...props}
				/>
			) : (
				<Input
					ref={ref}
					onBlur={onBlur}
					onFocus={onFocus}
					prefix={props.prefix}
					suffix={props.suffix}
					step={props.step && '0.1'}
					onInput={inputFirstLatterUppercase}
					{...props}
				/>
			)}
		</FormInputStyle>
	);
};

export default FormInput;
