import styled from 'styled-components';

const FormInputStyle = styled.div`
	--inputPadTB: var(--sp3x);
	--inputPadLR: var(--sp3x);
	--textareaHeight: var(--sp41x);

	input,
	textarea {
		padding: var(--inputPadTB) var(--inputPadLR);
		border-radius: var(--sp1x);
		background-color: rgba(99, 78, 195, 0.15);
		-webkit-backdrop-filter: blur(44px);
		backdrop-filter: blur(44px);
		width: 100%;
		font-size: var(--formSize);
		color: var(--white);
		transition: unset;
		border: 1px solid var(--white);
		font-family: var(--golos_text);
		font-weight: 400;

		&::placeholder {
			font-size: var(--formSize);
			color: var(--white);
		}

		@media (hover: hover) {
			&:hover {
				background-color: rgba(34, 33, 105, 0.15);
			}
		}

		&:active {
			color: rgba(255, 255, 255, 1);

			&::placeholder {
				color: rgba(255, 255, 255, 1);
			}
		}
	}

	textarea {
		height: var(--textareaHeight);
		min-height: var(--textareaHeight);
		max-height: var(--textareaHeight);
	}

	.ant-input-suffix,
	.ant-input-textarea-suffix {
		display: none;
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--textareaHeight: var(--sp29x);
		--inputPadTB: var(--sp3x);
		--inputPadLR: var(--sp3x);
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--textareaHeight: var(--sp29x);
		--inputPadTB: var(--sp2-5x);
		--inputPadLR: var(--sp2-5x);
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--textareaHeight: var(--sp29x);
		--inputPadTB: var(--sp2-5x);
		--inputPadLR: var(--sp2-5x);
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--textareaHeight: var(--sp29x);
		--inputPadTB: var(--sp2-5x);
		--inputPadLR: var(--sp2-5x);
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--textareaHeight: var(--sp29x);
		--inputPadTB: var(--sp2x);
		--inputPadLR: var(--sp2x);
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--textareaHeight: var(--sp21x);
		--inputPadTB: var(--sp1-5x);
		--inputPadLR: var(--sp1-5x);
	}
`;

export default FormInputStyle;
