import styled from 'styled-components';

const ButtonStyle = styled.div`
	/* --defaultBtnPadTB: var(--sp2-5x);
	--defaultBtnPadLR: var(--sp4x); */
	--defaultBtnPadTB: var(--sp1-5x);
	--defaultBtnPadLR: var(--sp3x);

	&.btn {
		width: fit-content;

		a,
		button {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: var(--h4);
			text-align: center;
			font-family: var(--golos_text);
			font-weight: 500;
			text-transform: uppercase;
			border-radius: var(--sp1x);
			opacity: 1;
			cursor: pointer;
			position: relative;
			overflow: hidden;
			isolation: isolate;
			padding: var(--defaultBtnPadTB) var(--defaultBtnPadLR);
		}

		&.secondary {
			a,
			button {
				background-color: transparent;
				color: var(--white);
				border: 1px solid var(--white);
				transition: all var(--trTime) ease-out;
			}

			@media (hover: hover) {
				&:hover {
					a,
					button {
						background-color: var(--white);
						color: var(--gradient02);
					}
				}
			}
		}
	}

	&.primary {
		a,
		button {
			background-color: var(--white);
			border: 1px solid var(--white);
			color: var(--gradient02);
			transition: all var(--trTime) ease-out;
		}

		@media (hover: hover) {
			&:hover {
				a,
				button {
					background-color: transparent;
					color: var(--white);
				}
			}
		}
	}

	/* //! Loading Animation */
	.btn-text {
		&.hide {
			opacity: 0;
		}
	}

	.loading-wrapper {
		position: relative;
	}

	.loader {
		position: absolute;
		top: calc(50% - var(--sp2x));
		left: calc(50% - var(--sp2x));
	}

	/* //! GLOBAL DISABLED */
	&.disabled {
		pointer-events: none;

		a,
		button {
			opacity: 0.5;
			cursor: default;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		/* --defaultBtnPadTB: var(--sp2x);
		--defaultBtnPadLR: var(--sp3x); */
		--defaultBtnPadTB: var(--sp1-5x);
		--defaultBtnPadLR: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		/* --defaultBtnPadTB: var(--sp2x);
		--defaultBtnPadLR: var(--sp3x); */
		--defaultBtnPadTB: var(--sp1-5x);
		--defaultBtnPadLR: var(--sp2-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		/* --defaultBtnPadTB: var(--sp2x);
		--defaultBtnPadLR: var(--sp3x); */
		--defaultBtnPadTB: var(--sp1-5x);
		--defaultBtnPadLR: var(--sp2-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		/* --defaultBtnPadTB: var(--sp2x);
		--defaultBtnPadLR: var(--sp3x); */
		--defaultBtnPadTB: var(--sp1-5x);
		--defaultBtnPadLR: var(--sp2-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		/* --defaultBtnPadTB: var(--sp2x);
		--defaultBtnPadLR: var(--sp3x); */
		--defaultBtnPadTB: var(--sp1x);
		--defaultBtnPadLR: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		/* --defaultBtnPadLR: var(--sp2x);
		--defaultBtnPadTB: var(--sp1-5x); */
		--defaultBtnPadTB: var(--sp1x);
		--defaultBtnPadLR: var(--sp2x);
	}
`;

export default ButtonStyle;
