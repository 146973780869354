import { useMemo } from 'react';

//* HOC's
import { withLanguageContext } from '@/context';

//* Components
import CustomLink from '../CustomLink';
import ButtonLoading from '../ButtonLoading';

//* Style
import ButtonStyle from './style';

const Button = ({ text, className, onClick, url, translate, type = 'button', disabled = false, children, external, isLoading = false }) => {
	const Component = useMemo(() => (url ? CustomLink : 'button'), [url]);

	const customProps = useMemo(() => {
		if (url) {
			return {
				url,
				text,
				external,
			};
		} else {
			return { type, disabled };
		}
	}, [url, text, external, type, disabled]);

	return (
		<ButtonStyle
			onClick={onClick || null}
			className={`btn primary ${className || ''} ${disabled ? 'disabled' : ''}`}>
			<Component {...customProps}>
				<div className={'loading-wrapper'}>
					{<span className={`btn-text ${isLoading ? 'hide' : ''}`}>{translate(text)}</span> || children}
					{isLoading ? <ButtonLoading /> : null}
				</div>
			</Component>
		</ButtonStyle>
	);
};

export default withLanguageContext(Button, ['translate']);
