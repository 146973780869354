import { forwardRef } from 'react';
import Link from 'next/link';

//* HOC's
import { withLanguageContext } from '@/context';

const CustomLink = forwardRef(({ url, className, prefetch = true, scroll = true, replace = false, external = false, translate, ...props }, ref) => {
	return (
		<Link
			ref={ref}
			scroll={scroll}
			replace={replace}
			prefetch={prefetch}
			className={`link-item ${className || ''}`}
			rel={external ? 'noopener noreferrer' : ''}
			target={external ? '_blank' : props.target || '_self'}
			href={typeof url === 'object' ? { ...url, pathname: external ? `${url.pathname}` : `/${url.pathname}` } : external ? url : `${url}`}
			aria-label={props.ariaLabel ? translate(props.text ? props.text.replaceAll('/', '') : props.ariaLabel || (typeof url == 'object' ? url.pathname.replaceAll('/', '') : url.replaceAll('/', ''))) : url.split('/').join('')}>
			{props.children || props.content}
		</Link>
	);
});

export default withLanguageContext(CustomLink, ['translate']);
